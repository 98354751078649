import { Box } from '@mui/material';
import Copyright from './Copyright';
const Footer = () => {
    return (
        <Box sx={{
            p:15
        }}>
            <Copyright></Copyright>
        </Box>
    );
}
export default Footer;